<template>
    <div class="g-grid-box">
        <div v-if="$store.state.menuList.length<1">
            {{ $t('global.正在加载菜单') }}
        </div>
        <div class="grid-box" v-else>
            <div
                    class="grid-box-row"
                    v-for="(row, index) in menuList"
                    :key="index"
            >
                <div
                        class="grid-box-item"
                        @click="handleGoPath(item)"
                        v-for="(item, index2) in row"
                        :key="index2"
                        :style="{'margin-left':colMargin[col]+'px','margin-right':colMargin[col]+'px'}"
                >
                    <div class="item-box">
                        <div
                                class="item-box-icon"
                                :class="item.icon"
                        ></div>
                        <div class="item-box-title"> {{ $t(`menu['${item.title}']`) }} </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import api from '@/api'
import tool from '@/tool'
import getConfig from '@/config'
import Vue from 'vue'
import VueCookie from 'vue-cookie'

export default Vue.extend({
  components: {
  },
  directives: {},
  filters: {},
  props: {
  },
  data () {
    return {
      col: this.$route.meta.col || 4,
      colMargin: {
        2: '50',
        3: '50',
        4: '20'
      },
      menuList: [

      ]

    }
  },
  computed: {

  },
  watch: {
    $route  () {

    }
  },
  beforeCreate () {
  },
  created () {
    const tempHomeMenu = this.$store.state.menuList
    console.log(tempHomeMenu,'tempHomeMenu')
    var rowList = []
    var k = 0
    for (var i = 0; i < tempHomeMenu.length; i++) {
      if (!rowList[k]) {
        rowList[k] = []
      }
      rowList[k].push(tempHomeMenu[i])
      var col = this.col
      if (rowList[k].length == col) {
        k++
      }
    }
    if (tempHomeMenu.length == 0) {
      this.$alert('请联系管理员开通权限菜单', '提示', {
        type: 'warning'
      }).then(() => {
        tool.clearLocalData()
        this.$router.replace('/home')
      })
      return
    }
    console.log('tempHomeMenu', tempHomeMenu)

    if (tempHomeMenu.length == 1) {
    //   this.handleGoPath({ path: tempHomeMenu[0].path }, true)
    }
    console.log('rowList', rowList)
    this.menuList = rowList
  },
  beforeMount () {
  },
  mounted () {

  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {

    },
    funRefresh () {
      if (!this.$refs.tableRef.onSubmit) return false
      this.$refs.tableRef.onSubmit()
    },
    handleGoPath (item, home) {
      if (!item.path) {
        this.$message.error('页面路径未配置')
        return
      }
      if (item.path.indexOf('http') > -1 || item.path.indexOf('https') > -1) {
        var toHome = home ? '&type=home' : ''
        window.location.href = item.path + '?lang=' + localStorage.getItem('locale') + toHome
      } else if (!VueCookie.get('client-token')) {
        location.href = getConfig().pathZeus + '/login?redirect=' + encodeURIComponent(item.path + '?sid=' + item.id)
      } else {
        this.$router.push(item.path)
      }
    },
    getLogoAndName () {

    }
  }
})
</script>
<style lang="scss" scoped>
    @import "./../assets/css/global-variable.scss";

    .g-grid-box {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: #1b1c4c;
        background-size: 1400px;
    }

    .grid-box {
        width: 100%;
        position: relative;
        margin-top: -50px;

    }
    .item-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .item-box-icon {
            width: 168px;
            height: 194px;

            @for $i from 1 through 8 {
                &.icon#{$i} {
                    background: url("~assets/img/home/icon#{$i}.png") no-repeat;
                    background-size: 100%;
                }
            }

            &.icon-bill-icon1 {
                background: url("~assets/img/home/icon1-bill-icon1.png") no-repeat;
                background-size: 100%;
            }
            &.icon-bill-icon2 {
                background: url("~assets/img/home/icon1-bill-icon2.png") no-repeat;
                background-size: 100%;
            }
            &.icon-base-icon1 {
                background: url("~assets/img/home/icon2-base-icon1.png") no-repeat;
                background-size: 100%;
            }
            &.icon-base-icon2 {
                background: url("~assets/img/home/icon2-base-icon2.png") no-repeat;
                background-size: 100%;
            }
            &.icon-base-icon3 {
                background: url("~assets/img/home/icon2-base-icon3.png") no-repeat;
                background-size: 100%;
            }
            &.icon-base-icon4 {
                background: url("~assets/img/home/icon2-base-icon4.png") no-repeat;
                background-size: 100%;
            }
            &.icon-base-icon5 {
                background: url("~assets/img/home/icon2-base-icon5.png") no-repeat;
                background-size: 100%;
            }
            &.icon-product-icon1 {
                background: url("~assets/img/home/icon3-product-icon1.png") no-repeat;
                background-size: 100%;
            }

            &.icon-product-icon2 {
                background: url("~assets/img/home/icon3-product-icon2.png") no-repeat;
                background-size: 100%;
            }
            &.icon-zhihu-icon1 {
                background: url("~assets/img/home/icon6-zhihu-icon1.png") no-repeat;
                background-size: 100%;
            }
            &.icon-zhihu-icon2 {
                background: url("~assets/img/home/icon6-zhihu-icon2.png") no-repeat;
                background-size: 100%;
            }
            &.icon-zhihu-icon3 {
                background: url("~assets/img/home/icon6-zhihu-icon3.png") no-repeat;
                background-size: 100%;
            }
            &.icon1-tanshang-icon1 {
                background: url("~assets/img/home/icon1-tanshang-icon1.png") no-repeat;
                background-size: 100%;
            }
            &.icon1-tanzhijian-icon1 {
                background: url("~assets/img/home/icon1-tanzhijian-icon1.png") no-repeat;
                background-size: 100%;
            }
            // &.icon1-tanbaobiao-icon1 {
            //     background: url("~assets/img/home/icon1-tanzhijian-icon1.png") no-repeat;
            //     background-size: 100%;
            // }

        }
        .item-box-title {
            text-align: center;
            margin-top: 20px;
            font-size: 18px;
            color: $text-color-white;
            font-weight: bold;
        }
    }
    .grid-box-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .grid-box-item {
        width: 233px;
        height: 233px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 10px;
        cursor: pointer;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 200ms;
        &:hover {
            background: #00995b;
            color: #ffffff;
            transform: scale(1.05);
        }
    }
</style>
